export default function DefaultParagraphMobile() {
  const days = ['Ti auguro un buon inizio di settimana utente, che la Forza sia con te! 🤯🤯🤯<br/><br/>','Spero che la tua settimana sia partita nel migliore dei modi utente, buona navigazione! 👋👋<br/><br/>','Ti auguro un buon proseguimento di navigazione utente, spero che ti diverta navigare il mio sito! 🚀🚀🚀<br/><br/>','Buon lavoro e buona giornata utente, daieh che il fine settimana è all\'orizzonte! 💪💪<br/><br/>','Detto questo, aperitivo?🍻🍸<br/><br/>','Spero di non averti annoiato utente, pensa a goderti il fine settimana!💃🕺<br/><br/>','Godiamoci gli ultimi sprazzi del week-end e prepariamoci a ricominciare la settimana nel migliore dei modi utente!<br/><br/>'];
  const date = new Date();
  const day = days[ date.getDay() - 1 ];
  const hours = ['Buonasera utente, cosa ti porta sul mio sito ad un ora così tarda?<br /><br />', 'Buongiorno utente!<br/><br/>', 'Buon pomeriggio utente!<br/><br/>', 'Buona sera utente!<br/><br/>'];
  const hour = hours[Math.round((date.getHours() / 6) - 1)];
  
  // Testo della Pagina
  const printContentV1 =
  hour + 'Mi chiamo Francesco Bianciardi, sono un Web Designer e in questa sezione cercerò di raccontarti un pò chi sono e cosa faccio.<br/><br/>(Per una panoramica più rapida su chi sono e cosa faccio, visita pure la mia <a href="/curriculum">pagina curriculum</a>).<br/><br/>Dopo aver conseguito la Laurea Triennale in Disegno Industriale presso l\'Università degli Studi di Firenze, ho deciso di specializzarmi in Web Design, iscrivendomi e completando il corso in Web Graphic Design dell\'<a href="https://www.nemoacademy.eu/" target="_blank">Accademia Nemo</a> di Firenze.<br/><br/>Da qui poi ho continuato a crescere nel mondo del lavoro, prima in studio, presso la <a href="https://www.maxmile.it/" target="_blank">Web Agency MaxMile</a>, poi nella libera professione, presso lo studio <a href="https://ragou.it/" target="_blank">Ragou Design</a>, dove ho collaborato con la designer <a href="https://ragou.it/" target="_blank">Laura Lavorini</a> e da cui mi sono ritirato a settembre 2023.<br/><br/>Durante queste esperienze ha avuto modo di conoscere e approfondire i vari aspetti della progettazione web dall\'attività di marketing, allo sviluppo in codice, passando dall\'UX / UI Design e dalla grafica web.<br/><br/>Nel tempo ho sviluppato un approccio progettuale mirato a considerare il progetto web nella sua totalità, cercando di comprendere al meglio le dinamiche che regolano i vari attori che interagiscono sulla scena e concentrando il mio lavoro sulla parte di usabilità, sviluppo e grafica. Questo mi ha spinto ad affascinarmi al mondo dello svilluppo Front-End, in cui mi vorrei maggiormente specializzare.<br/><br/>' + day;
  const printContentV2 =
    'Mi chiamo Francesco Bianciardi, sono un Web Designer e in questa sezione cercerò di raccontarti un pò chi sono e cosa faccio.<br/><br/>(Per una panoramica più rapida su chi sono e cosa faccio, visita pure la mia <a href="/curriculum">pagina curriculum</a>).<br/><br/>Dopo aver conseguito la Laurea Triennale in Disegno Industriale presso l\'Università degli Studi di Firenze, ho deciso di specializzarmi in Web Design, iscrivendomi e completando il corso in Web Graphic Design dell\'<a href="https://www.nemoacademy.eu/" target="_blank">Accademia Nemo</a> di Firenze.<br/><br/>Da qui poi ho continuato a crescere nel mondo del lavoro, prima in studio, presso la <a href="https://www.maxmile.it/" target="_blank">Web Agency MaxMile</a>, poi nella libera professione, presso lo studio <a href="https://ragou.it/" target="_blank">Ragou Design</a>, dove ho collaborato con la designer <a href="https://ragou.it/" target="_blank">Laura Lavorini</a> e da cui mi sono ritirato a settembre 2023.<br/><br/>Durante queste esperienze ha avuto modo di conoscere e approfondire i vari aspetti della progettazione web dall\'attività di marketing, allo sviluppo in codice, passando dall\'UX / UI Design e dalla grafica web.<br/><br/>Nel tempo ho sviluppato un approccio progettuale mirato a considerare il progetto web nella sua totalità, cercando di comprendere al meglio le dinamiche che regolano i vari attori che interagiscono sulla scena e concentrando il mio lavoro sulla parte di usabilità, sviluppo e grafica. Questo mi ha spinto ad affascinarmi al mondo dello svilluppo Front-End, in cui mi vorrei maggiormente specializzare.<br/><br/>';
  const randomParagraph = [printContentV1, printContentV2];
  const displayParagraph = randomParagraph[Math.round(Math.random())];
  return (
    <>
      <p className="mobile-about" dangerouslySetInnerHTML={{ __html: displayParagraph }}></p>
    </>
  );
}
